
import { Vue, Component } from "vue-property-decorator";
import InvoiceDetailsModal from "@/components/InvoiceDetailsModal.vue";
import { IInvoiceDetails } from "@/models/InvoiceDetails";
import { AdminService } from "@/api/AdminServiceApi";

@Component({ components: { InvoiceDetailsModal } })
export default class DashboardInvoices extends Vue {
  invoiceDetails: Array<IInvoiceDetails> = [];
  showInvoiceDetails: boolean[] | null[] = [];

  limit: number = 10;
  offset: number = 0;
  currentPage: number = 1;
  pageSize: number = 0;

  async created() {
    await AdminService.PendingInvoices()
      .then(async response => {
        this.invoiceDetails = response.data;
      })
      .catch(error => {});
  }

  get pagesCount(): number {
    return Math.ceil(this.invoiceDetails.length / this.limit);
  }

  get limitDisplay(): number {
    const pendingInvoicesCount = this.invoiceDetails.length;
    if (this.limit > pendingInvoicesCount) {
      return pendingInvoicesCount;
    }
    return this.limit;
  }

  paginateNext() {
    this.resetState();
    this.currentPage++;
    this.offset += this.limit;
  }

  paginatePrevious() {
    this.resetState();
    this.currentPage--;
    this.offset -= this.limit;
  }

  resetState() {
    this.showInvoiceDetails = [];
  }

  get quotationRequests(): Array<IInvoiceDetails> {
    const offset = this.offset;
    const pageSize = this.pageSize;
    const page = this.currentPage;
    const size = page * pageSize;
    this.pageSize = this.limit;
    return this.invoiceDetails.slice(offset, size);
  }

  showDetailedInformation(index: number) {
    Vue.set(this.showInvoiceDetails, index, !this.showInvoiceDetails[index]);
  }

  showingDetailedInformation(index: number): boolean {
    return this.showInvoiceDetails[index] == true;
  }

  async markAsInvoiced(index: number, details: IInvoiceDetails) {
    this.$swal
      .fire({
        titleText:
          details.billingDetails.companyName +
          " - " +
          details.billingDetails.businessCode,
        text: "Summa: " + details.invoice.amount + " €",
        confirmButtonText: "Markera som fakturerad",
        icon: "info",
        showCloseButton: true
      })
      .then(async result => {
        if (result.isConfirmed) {
          await AdminService.MarkAsInvoiced(details.invoice.id)
            .then(() => {
              this.$swal.fire({
                titleText: "Markerad som fakturerad",
                confirmButtonText: "Ok",
                icon: "success"
              });
              this.invoiceDetails.splice(index, 1);
              this.showInvoiceDetails[index] = false;
            })
            .catch(() => {
              this.$swal.fire({
                titleText: "Någonting gick fel",
                confirmButtonText: "Ok",
                icon: "error"
              });
            });
        }
      });
  }
}


import { Vue, Component, Prop } from "vue-property-decorator";
import { IInvoiceDetails } from "@/models/InvoiceDetails";

@Component({})
export default class InvoiceDetailsModal extends Vue {
  @Prop({
    default: () => ({
      invoice: { createdDate: "", amount: 0 },
      billingDetails: {
        companyName: "",
        businessCode: "",
        contactPerson: "",
        email: "",
        mobilePhone: "",
        language: ""
      },
      subscriptionDetails: {
        expired: false,
        created: "",
        expiryDate: "",
        subscriptionPriceModel: {
          id: 0,
          basePrice: 0,
          nrOfMonths: 0,
          monthlyPricePerExtraCategory: 0,
          monthlyPricePerExtraRegion: 0,
          monthlyPricePerMultipleExtraCategories: 0,
          monthlyPricePerMultipleExtraRegions: 0
        },
        invoicingMethod: {
          id: 0,
          method: ""
        },
        subscribedCategories: [],
        subscribedRegions: []
      }
    })
  })
  invoiceDetails!: IInvoiceDetails;

  get invoice() {
    return this.invoiceDetails.invoice;
  }

  get billingDetails() {
    return this.invoiceDetails.billingDetails;
  }

  get subscriptionDetails() {
    return this.invoiceDetails.subscriptionDetails;
  }

  get invoicingMethod() {
    return this.subscriptionDetails.invoicingMethod;
  }

  get priceModel() {
    return this.subscriptionDetails.subscriptionPriceModel;
  }

  get subscribedCategories() {
    return this.subscriptionDetails.subscribedCategories;
  }

  get subscribedRegions() {
    return this.subscriptionDetails.subscribedRegions;
  }

  close() {
    this.$emit("close");
  }
}
